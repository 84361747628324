<script setup lang="ts">
const { data: appInitData } = useAppInitData();
const { exitFullscreen } = useFullscreenToggle();
const { open, isOpen } = useFunrizeModals();
const activeWidgetSeason = useLocalStorage<null | boolean>("activeWidgetSeason", null);

const isSeasonEnabled = computed(() => appInitData.value?.season?.isEnabled);
const progressValue = computed(() => 314.159 - (appInitData.value?.season?.totalProgressPercents || 2) * Math.PI);

const handleOpenTower = () => {
	exitFullscreen();
	dispatchGAEvent({
		event: "click_button",
		form_name: "season_funtastic",
		type: appInitData.value?.gameMode === "SweepStakes" ? "winnings" : "coins",
		location: isOpen("LazyOModalGame") ? "ingame_popup" : "navigation_bar_mob"
	});
	activeWidgetSeason.value = null;
	open("LazyOModalSeasonTower", { isSoundOff: isOpen("LazyOModalGame"), isBackToGame: isOpen("LazyOModalGame") });
};
</script>
<template>
	<div v-if="isSeasonEnabled" class="season-progress" @click="handleOpenTower">
		<div :class="['img-holder', { 'is-active': !!activeWidgetSeason }]">
			<NuxtImg src="/nuxt-img/seasons/funtastic/mask.png" alt="mask" format="avif" width="44" height="44" />
		</div>
		<NuxtIcon name="40/progress" filled />
	</div>
</template>
<style scoped lang="scss">
.season-progress {
	display: flex;
	position: relative;
	width: 48px;
	height: 48px;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	background-color: var(--cacem);
	border-radius: 50%;

	.nuxt-icon {
		display: inline-flex;
		position: absolute;
		top: -3px;
		left: -1px;
		font-size: 50px;

		&:deep(svg) {
			margin: 0;

			.progress-circle {
				stroke-dashoffset: v-bind(progressValue);
				stroke: #f1c21c;
			}
		}
	}

	.img-holder.is-active {
		animation: shake 4s infinite;
	}
}
@keyframes shake {
	0%,
	80%,
	100% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(-10deg);
	}
	95% {
		transform: rotate(10deg);
	}
}
</style>
